import * as React from 'react';
import { styled } from 'linaria/react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Link } from 'gatsby';
import CommonLayout, { SubDirectorySection } from './CommonLayout';
import Title from '../components/Title';
import Logo from '../assets/svg/logo/info.imp.svg';
import Arrow from '../assets/svg/arrow/button_white.svg';
import { formatDate } from '../Utils';

const Date = styled.h3`
  font-family: var(--font-jp-sans);
  font-size: 1.5em;
  font-weight: 400;
  border-bottom: 1px solid var(--theme-gold);
  padding-bottom: 1em;
`;

const NewsTitle = styled.h2`
  font-weight: 100;
  font-size: x-large;
  padding: 1em 0;
`;

const Thumb = ({ image, title }: { image?: GatsbyTypes.MicrocmsNewsImage; title: string }) => (
  <div
    style={{
      background: 'var(--theme-blue)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      // maxWidth: '100%',
      // height: '100%',
    }}
  >
    {image?.url ? (
      <img src={`${image.url}?w=800`} style={{ maxWidth: '100%' }} alt={title || '神保町 五木田'} />
    ) : (
      <Logo
        title="神保町 五木田"
        fill="var(--white)"
        style={{ maxHeight: '200px', marginLeft: '-3.4em', padding: '1em 0' }}
      />
    )}
  </div>
);

const Body = styled.p`
  margin: 2em 0 4em 0;
  font-family: var(--font-jp-sans);
  font-size: 1.2em;
  font-weight: 300;
  * {
    line-height: 1.5em;
  }
`;

const Footer = ({ index, total }: { index: number; total: number }) => (
  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
    <div
      style={{
        position: 'relative',
        paddingRight: '4em',
        background: `center left url(${Arrow}) no-repeat`,
        transform: 'scale(-1,1)',
        opacity: index > 0 ? 1 : 0,
      }}
    >
      <p style={{ lineHeight: 1, paddingLeft: '1em', transform: 'scale(-1,1)', background: 'var(--theme-gray)' }}>
        newer
      </p>
      {index > 0 && (
        <Link
          to={`/news/${index - 1}`}
          style={{
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
          }}
        />
      )}
    </div>
    <div style={{ position: 'relative', margin: '0 4em' }}>
      お知らせ一覧
      <Link
        to="/newslist"
        style={{
          display: 'block',
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
        }}
      />
    </div>
    <div
      style={{
        opacity: index > 10 || index + 1 > total ? 1 : 0,
        position: 'relative',
        paddingRight: '4em',
        background: `center right  url(${Arrow}) no-repeat`,
      }}
    >
      <p style={{ lineHeight: 1, paddingRight: '1em', background: 'var(--theme-gray)' }}>older</p>
      {(index > 10 || index + 1 >= total) && (
        <Link
          to={`/news/${index + 1}`}
          style={{
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
          }}
        />
      )}
    </div>
  </div>
);
const NewsPage = ({
  pageContext,
}: {
  pageContext: { item: GatsbyTypes.MicrocmsNews; index: number; total: number };
}) => {
  const device = useBreakpoint();
  const isSmallViewer = device.m;

  const { item, index, total } = pageContext;
  const title = item?.title || '最新ニュース';

  return (
    <CommonLayout title="お知らせ一覧" article>
      <SubDirectorySection style={{ width: '100%' }}>
        <Title
          label="News"
          ruby="お知らせ"
          align="center"
          style={{ alignSelf: isSmallViewer ? 'center' : 'flex-start' }}
        />
        <article style={{ width: '100%' }}>
          <div style={{ padding: isSmallViewer ? '3em' : '4em 4em 4em 0' }}>
            <Date>{formatDate(item.publishedAt)}</Date>
            <NewsTitle>{title}</NewsTitle>
            <Thumb image={item.image} title={title} />
            <Body>
              <span dangerouslySetInnerHTML={{ __html: item.body || '' }} />
            </Body>
            <Footer index={index} total={total} />
          </div>
        </article>
      </SubDirectorySection>
    </CommonLayout>
  );
};

export default NewsPage;
